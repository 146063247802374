import emojiRegex from 'emoji-regex';

export default ({
  input,
  shouldRemoveAll = true,
  emojiToRemove,
}: { input: string; shouldRemoveAll?: boolean; emojiToRemove?: string }) => {
  if (emojiToRemove) {
    return input.replace(emojiToRemove, '').trim();
  }

  if (shouldRemoveAll) {
    const regex = emojiRegex();
    return input.replace(regex, '').trim();
  }

  return input;
};
